import React from 'react'
import { number, string } from 'prop-types'
import getAnalysisStatusIcon from 'helpers/getAnalysisStatusIcon'

// this is the icon that is used to display the status of an individual analysis
// as well as the rolled up analysis statuses for a project.

const AnalysisStatusIcon = props => {
  const {
    iconClasses = '',
    idRoot,
    analysisStatus,
    analysisStatusId,
  } = props

  return (
    <i
      className={`${iconClasses} ${getAnalysisStatusIcon(analysisStatusId)}`}
      id={`${idRoot}-icon`}
      title={analysisStatus} />
  )
}

AnalysisStatusIcon.propTypes = {
  analysisStatus: string.isRequired,
  analysisStatusId: number,
  iconClasses: string,
  idRoot: string.isRequired,
}

export default AnalysisStatusIcon
