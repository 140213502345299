import { loadingToggler } from 'redux/modules/loading'
import { postPathWarnings } from 'helpers/api'
import { parseValidationMessages } from 'redux/helpers'
import { setPathValidationsMessagesInDrawer } from 'redux/modules/pathValidationsDrawer'
import { UNCAUGHT_ERROR } from 'errors'

// NOTE - any errors will be thrown to the parent caller.
// This will populate the validation drawer with the error messages
// in place of dealing with the errors here.

const checkPathsWarningValidations = (pathIds, callback) => (dispatch, getState) => (
  dispatch(loadingToggler(
    async () => {
      const {
        entities,
        message,
      } = await postPathWarnings(pathIds)

      // if there are no entities returned from the API, then there are no warnings for the given pathIds
      // we'll get out of this thunk. Check to see if we were given a callback or not.
      // NOTE - for scenarios like Quick Add, there's no need for a callback here
      if (!entities.length) {
        return callback && callback()
      }

      // but, if there were warnings, we need to present them to the user

      // first parse the warning messages
      const parsedValidationMessages = parseValidationMessages(entities)

      // this will hold all our validation messages where the key is the path id
      // and the values will be validation message objects
      const newPathValidations = {}

      // loop over the pared messages and construct the data model for the Validations Drawer to consume.
      Object.entries(parsedValidationMessages).forEach(([key, value]) => {
        newPathValidations[key] = {
          errors: [],
          heading: value.heading,
          warnings: value.messages,
        }
      })

      dispatch(setPathValidationsMessagesInDrawer(
        message || UNCAUGHT_ERROR,
        newPathValidations,
        // passing this allows us to clear messages for paths which did not return anything
        // from the warnings check above.
        pathIds,
      ))

      // even if there were warnings, we technically still updated the DB records
      // so we should still display success message.
      return callback && callback()
    })
  )
)

export default checkPathsWarningValidations
