import React from 'react'
import { array, object, string } from 'prop-types'
import { Field } from 'redux-form'
import AggregateRowCheckbox from './AggregateRowCheckbox'
import SegmentEndInfo from './SegmentEndInfo'

const AggregateRow = props => {
  const {
    aggregateGroupMatches,
    checkboxGroupName,
    idRoot,
    segmentEndData,
  } = props

  return (
    <tr>
      <Field
        component={AggregateRowCheckbox}
        id={`${idRoot}-checkbox`}
        name={checkboxGroupName}
        aggregateGroupMatches={aggregateGroupMatches} />
      <SegmentEndInfo
        idRoot={`${idRoot}-callSignCascadeAggregateSegmentEnd`}
        segmentEndData={segmentEndData} />
    </tr>
  )
}

AggregateRow.propTypes = {
  aggregateGroupMatches: array.isRequired,
  checkboxGroupName: string.isRequired,
  idRoot: string.isRequired,
  segmentEndData: object.isRequired,
}

export default AggregateRow
