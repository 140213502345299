import React, { useCallback, useEffect, useRef } from 'react'
import { object, string } from 'prop-types'
import { Jodit } from 'jodit'


const UpdatedWYSIWYG = props => {
  const {
    config,
    id,
    input,
  } = props

  const textArea = useRef(null)

  const handleChange = useCallback(value => input.onChange(value), [])

  useEffect(() => {
    const element = textArea.current
    element.id = id
    element.name = input.name

    textArea.current = Jodit.make(element, config)
    textArea.current.events.on('change', handleChange)

    return () => {
      textArea.current.destruct()
      textArea.current = element
    }
  }, [config])

  return (
    <div style={{ all: 'initial' }}>
      <textarea
        ref={textArea}
        {...input} />
    </div>
  )
}

UpdatedWYSIWYG.propTypes = {
  config: object,
  id: string.isRequired,
  input: object.isRequired,
}

export default UpdatedWYSIWYG
