export const apiPath = 'https://comsearch.dev.surgeforward.com:8443'

// TODO - add copyright header and remove the above once CI process is updated to use new UI production build method

export const unitTypes = [{
  label: 'US',
  value: 'US',
}, {
  label: 'SI',
  value: 'SI',
}]

// Keeps the degree of precision the same for all units across the application.
export const precisionKm = 3
export const precisionM = 3

export const precisionMi = 3
export const precisionFt = 2

// NOTE - these are used because there are a few places in the UI where we present
// precision differently for readonly values.
// These constants should not be used when handling user editable data.
export const readonlyPrecisionM = 2
export const readonlyPrecisionFt = 1
