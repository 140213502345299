
import React from 'react'
import { object, string } from 'prop-types'
import AnalysisStatusIcon from 'components/InterferenceAnalysis/AnalysisStatusIcon'

const FormatAnalysisStatus = ({ idRoot, rowData }) => (
  <AnalysisStatusIcon
    analysisStatus={rowData.analysisStatus}
    analysisStatusId={rowData.analysisStatusId}
    idRoot={idRoot} />
)

FormatAnalysisStatus.propTypes = {
  iconClasses: string,
  idRoot: string.isRequired,
  rowData: object.isRequired,
}

export default FormatAnalysisStatus
